/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Stage, OrbitControls } from "@react-three/drei";
import { Suspense } from "react";
import React, { useRef } from "react";
import "./bookshelf.css";
import { Link } from "react-router-dom";


export function Model(props) {
    const { nodes,  } = useGLTF("./bookshelf_02_2023_lowpoly.glb");
    return (
        <group {...props} dispose={null}>
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.mesh_0.geometry}
                material={nodes.mesh_0.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.mesh_0_1.geometry}
                material={nodes.mesh_0_1.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.mesh_0_2.geometry}
                material={nodes.mesh_0_2.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.mesh_0_3.geometry}
                material={nodes.mesh_0_3.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.mesh_0_4.geometry}
                material={nodes.mesh_0_4.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.mesh_0_5.geometry}
                material={nodes.mesh_0_5.material}
            />
            <mesh
                castShadow
                receiveShadow
                geometry={nodes.mesh_0_6.geometry}
                material={nodes.mesh_0_6.material}
            />
        </group>
    );
}

useGLTF.preload("./bookshelf_02_2023_lowpoly.glb");

export function BookShelf() {
    const ref = useRef()
    return (
        <>
        {/* A home button */}
        <nav><h2> <Link to="/">Home</Link></h2></nav>
        <h1>
            Books of 2022-23
        </h1>
        <div id="bookshelf">
            <Canvas shadows dpr={[1, 2]} camera={ref}>
                <Suspense fallback={null}>
                    <Stage controls={ref} preset="rembrandt" intensity={1} environment="city">
                        <Model position={[1.96, 0.15, 4.8]} rotation={[-3.11, 0.38, -3.13]} />
                    </Stage>
                </Suspense>
                <OrbitControls ref={ref} />
            </Canvas>
        </div>
        {/* A link to my goodreads read shelf */}
        <h2>
        <a href="https://www.goodreads.com/review/list/36159886?page=1&shelf=">My Goodreads Read Shelf</a>
        </h2>
        </>
    )
}