import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './cupboard.css'; // Assuming you have your CSS here

export function Cupboard() {
  const [selectedImg, setSelectedImg] = useState(null);

  return (
    <>
      <nav><h2><Link to="/">Home</Link></h2></nav>
      <h1>Cupboard</h1>
      <div className='body'>
      <div className="cupboard-grid">
        {require.context("../public/cupboard", false, /\.(JPG)$/).keys().map((item, index) => (
          <img 
            key={index} 
            className="grid-image aura-effect" 
            src={require("../public/cupboard" + item.slice(1))} 
            alt={item} 
            onClick={() => setSelectedImg(require("../public/cupboard" + item.slice(1)))}
          />
        ))}
      </div>
      {selectedImg && (
        <div className="backdrop" onClick={() => setSelectedImg(null)}>
          <img src={selectedImg} alt="Expanded" className="expanded-image" />
        </div>
      )}
      </div>
    </>
  );
}