// A custom markdown element with all plugins pre-loaded that I can
// use to import markdown content from a file or add it inline

import React from 'react'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import remarkMath from 'remark-math'
import rehypeMathJax from 'rehype-mathjax'
import './Markdown.css'

export class Markdown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            text: null
        }
    }

    componentDidMount() {
        console.log(this.props)
        console.log(typeof this.props.fname)
        console.log(typeof this.props.fname == 'string')
        if (typeof this.props.fname == 'string') {
            fetch(this.props.fname)
                .then(response => response.text())
                .then(text => {
                    console.log(text)
                    this.setState({ text: text })
                })
        }
    }

    render() {
        return (
            <div className="md">
                <ReactMarkdown
                    remarkPlugins={[remarkMath, gfm]}
                    rehypePlugins={[rehypeMathJax]}
                    children={this.state.text}
                />
            </div>
        )
    }
}
