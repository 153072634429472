import "./App.css";
import React from "react";
import { Route, Routes, Link, HashRouter } from 'react-router-dom';
import { BookShelf } from "./bookshelf";
import { Markdown } from "./Markdown";
import {Cupboard} from "./cupboard";
// import {M} from 'materialize-css'
// import 'materialize-css/dist/css/materialize.min.css';

export function Home() {
  return (
    <div id="main">
      <h1>Kartik Arcot</h1>
      <div>
      <Markdown fname="/bio.md"/>
      </div>
      <h1> <Link to="/bookshelf">Bookshelf</Link></h1>
      <h1> <Link to="/cupboard">Cupboard</Link></h1>
      {/* A web link to my gomoku game */}
      <h1> <a href="https://gomoku.karcot.com">Gomoku</a> </h1>
      <h1> <a href="https://lox.karcot.com">Lox</a> </h1>
      <h1> <a href="http://ttmb.karcot.com">Talk To My Books</a></h1>
      <h1> <a href="http://reader.karcot.com">Speed Reader Prototype</a></h1>
    </div>
  );
}

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bookshelf" element={<BookShelf />} />
        <Route path="/cupboard" element={<Cupboard/>} />
      </Routes>
    </HashRouter>
  );
}
